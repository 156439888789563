<template>
      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">
       <Shipping :id="parm" v-if="ship == true"/>

                  <div class="row" v-if="content">
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-end flex-wrap">
                
               
                                    <div class="mt-3" >
                      <p class=" d-flex justify-content-center align-self-center " ><i class="mdi mdi-cube-outline icon-md mr-1"></i> 
                         <span class=" py-2 " >Nouveau colis</span>
                       </p>
                    </div>


                </div>
               
              </div>
            </div>
          </div>

       
          <div class="row">
            <div class="row " style="margin-top : 1%;">
                       <div class="col-12">
                               <div  v-if="error" class="alert alert-danger text-center" role="alert">
                      {{error}}
                </div>
                       </div>
                     </div>
           
    
     
<div class="row">
  
  </div> 
                  <form class="forms-sample" id="myform" @submit.prevent="handlesubmit" style="width : 100%;">
                    
              <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Expédition :  </h4>
                                                           <loading  class="d-inline" v-if="isLoading" :active="true" :width="20" :height="20" color="blue"></loading>

                  <div class="d-flex row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Client : </label>
                      <div class="col-lg-7">
                        <ejs-combobox required id='combobox' :dataSource='clients' v-model:value="clientSelected" popupHeight="400px"   :fields='fields' @change="getMailReception" ></ejs-combobox>
                        
           
                      </div>
<!--                       <button @click=showClient> show client</button>
 -->                      <button type="button" class="btn btn-white btn-rounded btn-icon" @click="addClient"><i class="mdi mdi-plus text-success"></i></button>

                      

                    </div>*
                
  
                      </div>
                     
                  </div>
                  <!----------------->
                   <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Livraison : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
                           <select required class="form-control form-select form-select-md mb-3" aria-label="Default select example" v-model="shipment.type_expedition"  >
                      <option  value="domicile" >Domicile</option>
                      <option  value="point relais">Point relais</option>
                      </select>
                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
                         <!--------------------->
                                    <!-- <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label" >Date demandée : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
              <input type="date" class="form-control" id="exampleInputEmail2" placeholder="Email" v-model="shipment.date_exp_demandee" required>
              

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div> -->
                   
                  <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Point relais : </label>
                      <div class="col-lg-7">
                        <ejs-combobox required id='combobox' :dataSource='relais' v-model:value="relaySelected" popupHeight="400px"   :fields='fields' ></ejs-combobox>
                        
           
                      </div>
                      <button type="button" class="btn btn-white btn-rounded btn-icon" @click="addrelay"><i class="mdi mdi-plus text-success"></i></button>

                      

                    </div>
                
  
                      </div>
                     
                  </div>

                   <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Mail de reception : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
                                              <input type="email" class="form-control" id="mailReception" placeholder="mail@mail.com "   v-model="shipment.mail_reception" required>


                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>


           
                  <!---------------->

                </div>
              </div>
            </div>
            <!-------------------------------->

                      <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Vendeur et stockage :  </h4>
                     <div class="row">
                    <div class="col-12">
                   <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Vendeurs : </label>
                      <div class="col-lg-7">
                        <ejs-combobox required id='combobox' :dataSource='vendeurs' v-model:value="sellerSelected" popupHeight="400px"   :fields='fields' @change="getSellerCity" ></ejs-combobox>
                        
           
                      </div>
                      <button type="button" class="btn btn-white btn-rounded btn-icon" @click="addvendeur"><i class="mdi mdi-plus text-success"></i></button>

                      

                    </div>
                
  
                      </div>
                     
                  </div>
                  <!---------------->
                  <div class="row">    <!-- <ejs-combobox id='combobox' :dataSource='sportsData' placeholder='Select a game'></ejs-combobox> -->
</div>
                  
                                      <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Assurance : </label>
                      <div class="col-lg-8">
                            <div class="form-group">
                           <select required class="form-control form-select form-select-md mb-3" aria-label="Default select example" v-model="shipment.assurance" >
                      <option value="forfitaire">forfitaire</option>
                      <option value="adv">Ad Valorem</option>
                      </select>
                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
         
                                <!-- <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Date d'enlevement : </label>
                      <div class="col-lg-8">
                            <div class="form-group">
              <input type="date" class="form-control" id="exampleInputEmail2"  required v-model="shipment.date_enlevement_demandee">

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div> -->
                                    <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Centre de tri : </label>
                      <div class="col-lg-8">
                            <div class="form-group">
                           <select class="form-control form-select form-select-md mb-3" aria-label="Default select example" v-model="depot_id" required >
                     <option v-for="item in depots" :value="item.id" :key="item.id" >
   {{item.nom}} -- {{ item.ville.city}}
    </option>
                      </select>
                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
                  
      

           
                  <!---------------->

                </div>
              </div>
            </div>
            <!---------------------------->
                    <div class="col-12 grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title"> Paiement  :  </h4>


                  <!----------------->
                  
                                      <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Valeur déclarée : </label>
                      <div class="col-lg-6">
                            <div class="form-group">
                                              <input type="number" class="form-control" id="exampleInputEmail2"  placeholder="Montant payé en Dirhams " required step="O.01" v-model="shipment.valeur" autocomplete="off">


                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
         
                                          <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">COD : </label>
                      <div class="col-lg-6">
                            <div class="d-flex form-group">
              <input type="number" class="form-control" id="exampleInputEmail2" placeholder="Montant restant en Dirhams " required step="0.01" v-model="shipment.cod" autocomplete="off">

                      </div>
                      

                      </div>
                    </div>
  
                      </div>
                     
                  </div>
                  
                  <!--               <div class="row">
                    <div class="col-12">
                       <div class="form-group row" style="margin-left : 4%;">
                      <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Montant de retour : </label>
                      <div class="col-lg-8">
                            <div class="form-group">
              <input type="number" class="form-control" id="exampleInputEmail2" placeholder="Montant à payer en cas de retour " required step="0.01" v-model="shipment.montant_retour">

                      </div>

                      </div>
                    </div>
  
                      </div>
                     
                  </div> -->
  <div class="row">
    <div class="col-12">
      <div class="form-group row required" style="margin-left : 4%;">
        <label for="exampleInputUsername2" class="col-lg-2 col-form-label">Poids : </label>
        <div class="col-lg-6">
          <div class="form-group">
            <input type="number" class="form-control" id="exampleInputEmail2" placeholder="En kilogramme " required step="0.01" v-model="shipment.poids" autocomplete="off">
          </div>
        </div>
      </div>
    </div>
  </div>
                  
      

           
                  <!-------------->


               
                   
          
                 
                   <div class="row">
                     <div class="col-lg-3">

                     </div>
                     <div class="col-lg-3" >
                                           <button type="submit" class="btn btn-success mr-2" style="width : 100%;" :disabled="isLoading">
                                             <span v-if="!isLoading" >Valider</span>
                          <loading v-if="isLoading" :active="true" :width="10" :height="10" color="white"></loading>
                        </button>
                


                     </div>
                     <div class="col-lg-3">
                                           <button type="button" class="btn btn-secondary" style="width : 100%;" @click="back()">Retour</button>

                     </div>
                   </div>
                     
                                        <div class="row " style="margin-top : 1%;">
                       <div class="col-12">
                               <div  v-if="msg" class="alert alert-danger text-center" role="alert">
                       {{msg}}
                </div>
                       </div>
                     </div>
                </div>
              </div>
            </div>
          </form>
 
         <!----------------------------------------->
         <!----------------modal for downloading pdf ----------->
         <div v-if="modalPdf">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
       <div class="modal-dialog bg-transparent modal-dialog-centered modal-lg" >
         <div class="modal-content bg-light " style="">
            <div class="modal-header bg-light">
                  <h5 class="modal-title w-100 text-center text-secondary"> Télchargement du bon de commande </h5>
                                  <button
                              type="button"
                              class="border-0 bg-transparent"
                              @click="closeModal()"
                            >
                              <i class="mdi mdi-close text-secondary fw-bold"></i>
                            </button>
              </div>
    
         <div class=" bg-light p-0">
         
          <div class="modal-body row  m-0 w-100 container d-flex justify-content-center">
        <p class="text-dark">Vous pouvez télécharger le bon de commande diréctement via le bouton "Téléchargr" ou plus tard via l'interface "Colis"</p>
      </div>
      <div class="modal-footer">
         <button type="button" class="btn btn-primary mr-2 "  @click="pdf()" :disabled="isLoading">
                                             <span v-if="!isLoading" >Télcharger maintenant</span>
                          <span v-else >Chargement en cours</span>
                        </button>
        <button type="button" class="btn btn-secondary" @click="closeModal()">Plus tard</button>
      </div>
          


<!-- Upload  -->







           
      
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>



    
          </div>
 <!--        <p>If you click on the "Hide" button, I will disappear.</p>

<button id="hide">Hide</button>
<button id="show">Show</button> -->

        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>
<script>
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import Shipping from '@/components/Shipping.vue'; 
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from 'vue3-loading-overlay';
import $ from 'jquery';
import { notify } from "@kyvg/vue3-notification";
// import Dropdown from 'vue-simple-search-dropdown';





export default {
  components : {
    Header,
    Footer,
    Shipping,
    Loading,
    // Dropdown
  },
  
    data() {
    return {
      me:"nouheyla",
      parm: '',
      modalPdf:false,
      password: '',
      error: '',
      clients: null,
      relais:null,
      vendeurs:null,
      depots:null,
      valeur: '',
      cod : '',
      poids : '',
      assurance : '',
      taxe : '',
      montant_retour : '',
      date_enlevement_demandee : '',
      search:'',
      nom: '',
      prenom:"",
      type_expedition: "",
      date_exp_demandee:"",
      client_id:"",
      relay_id:"",
      relay_nom:"",
      seller_id:"",
      seller_nom:"",
      depot_id : "",
      varclient: false,
      varrelais: false,
      varvendeur: false,
      msg:'',
      id: '',
      ref:'',
      ship : false,
      content : true,
      shipment : {},
      isLoading : false,
      fields:{ text: 'nom', value: 'id' },
      clientSelected:"",
      relaySelected:'',
      sellerSelected:'',
      activity:{},

  
    }
  },
  computed :{
     role(){
        
             return this.$store.getters.get_profile;
      },
          user(){
           // console.log(this.$store.getters.get_user)
        return this.$store.getters.get_user;
 },
     },
    create(){
      window.location.reload();

    },
mounted(){
  this.isLoading = true;
  this.afficherClients();
  this.afficherRelays();
  this.afficherSellers();
  // $('#test').selectpicker();
  this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getwarehouses',
      })    
     .then(res => {
       this.isLoading = false;
      console.log(res.data);
      this.depots = res.data;
     })
     .catch(err => {
       this.isLoading = false;
       if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else this.error = "Une erreur s'est produite ! "
      })   
  
},
 
    methods: {
    
    openModal(){
      this.modalPdf = true;

    },
    handlesubmit(){
      
       this.msg='';

        console.log(this.data);
         Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir ajouter ce colis?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.add();
     
                                }
                        }) 
           
                    },
    addActivity(id){
   this.activity.user_id= this.user.id;
   this.activity.action= "Add shipment";
   this.activity.model = "Shipment"
   this.activity.url=this.$store.getters.get_link+'/api/addshipment';
   this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
   this.activity.item_id = id;
     this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addactivity',
      t : this.activity
      })    
     .then(res => {
      console.log(res.data);
     })
     .catch(error => {
        console.log(error)
  

          
     }) 

 },
 showClient(){
   console.log(this.clientSelected);
 },
    afficherClients(){
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getclientsactived',
      })
      .then( res => {
        this.isLoading = false;
        this.clients = res.data;
        console.log("clients", this.clients)
        this.count = res.data.length;
       })
      .catch(err => {  
        this.isLoading = false;    
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })      
      },
       afficherRelays(){
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getrelaisactived',
      })
      .then( res => {
        this.isLoading = false;
        this.relais = res.data;
        this.count = res.data.length;
       })
      .catch(err => {
        this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })      
      },
       afficherSellers(){
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getvendeursactived',
      })
      .then( res => {
        this.isLoading = false;
        this.vendeurs = res.data;
        this.count = res.data.length;
       })
      .catch(err => {
        this.isLoading = false;
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })      
      },

      closeModal(){
        this.modalPdf = false;

      },
      


    add(){
      this.error = "";
      this.msg = "";
    console.log("test",!Number.isInteger(this.clientSelected));
      if(!Number.isInteger(this.clientSelected) || !Number.isInteger(this.relaySelected) || !Number.isInteger(this.sellerSelected)) this.msg = "Un de ces champs n'a pas été sélectionné : Client, Relais ou Vendeur!"
      else
      {this.shipment.client_id= this.clientSelected;
         this.shipment.relay_id = this.relaySelected;
         this.shipment.seller_id = this.sellerSelected;
         this.shipment.warehouse_id = this.depot_id;
         console.log("FORMULAIRE : "+this.shipment.client_id)
      this.isLoading = true;
      this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/addshipment',
      t : this.shipment,
      })    
     .then(res => {
      this.isLoading = false;
     // console.log(res.data);
      console.log("add shipment",res.data.id);
      this.addActivity(res.data.id)
      console.log("reussi");
      
      this.id = res.data.id;
      this.ref = res.data.ref;
      this.openModal();
        
     // console.log(this.ship)
     
      // this.$router.push(`/p/${this.parm}`);
     })
     .catch(err => {
       console.log("add shipment error")
           this.isLoading = false;
           if (!err.response) this.msg = "Problème réseau, Impossible de joindre le serveur" 
           else this.msg = "Une erreur s'est produite lors de l'ajout de ce colis. Veuillez contacter l'administrateur ! "
     
     })
    }
 },
 getMailReception(){
  console.log(this.clientSelected);
  var client = this.clients.find( record => record.id === this.clientSelected)
  console.log(client.mail_reception);
  if(client.mail_reception) this.shipment.mail_reception = client.mail_reception;
  else this.shipment.mail_reception = "";
 },
 getSellerCity(){
  console.log(this.sellerSelected);
  var vendeur = this.vendeurs.find( record => record.id === this.sellerSelected)
  console.log(vendeur.ville_id);
  var depot = this.depots.find( record => record.ville_id === vendeur.ville_id)
  var depot_casa = this.depots.find( record => record.ville_id === 1)
  console.log(depot);
  if(depot) this.depot_id = depot.id;
  else this.depot_id = depot_casa.id;
 },
 notification(){
        console.log("notify")
        
      notify({ type: "success", text: "The operation completed", title: "DONE" });
      },

       notificationError(){
   console.log("notify")
   
notify({ type: "error", text: "Shipment added succeufuly but the pdf could not be generated", title: "WARNING" });
 },
  pdf(){
       this.isLoading = true;
    console.log("pdf");
       this.$store.dispatch('getFile', {//data to be sended
        nameRoute : '/api/generate-pdf/'+this.id,
        ref:this.ref,
        type:"GET",
        name:"BC ",
        ext:".pdf"

      })
      .then( res => {  
        this.isLoading = false;    
        this.closeModal();
        this.notification();
        this.$router.push("/shipment");
        
       
       })
      .catch(err => {
        this.isLoading=false;
        console.log(err);
        this.notificationError();
      })  
  
  },
  back(){
    // $('#myform').trigger("reset");
    //document.getElementsByClassName("forms-sample").reset();
    this.$router.go(-1);
  },

  addClient(){
     
        this.$router.push("/addcustomer");
      
    },
      addrelay(){
     
        this.$router.push("/addrelay");
      
    },
       addvendeur(){
     
        this.$router.push("/addseller");
      
    },


    /* getInfos(id, nom, prenom){
      this.search = '';
      this.varclient = true;
      
        this.client_id=id;
      this.nom = nom;
      this.prenom = prenom;
      
      document.getElementById("selection").innerHTML = this.nom+" "+this.prenom;
      console.log(this.client_id);
     // this.client_id=id;
      
      
    
    }, */
   /*  getInfosrelais(id, nom){
      this.search = '';
       this.varrelais = true;
      
        this.relay_id=id;
      this.relay_nom = nom;
      console.log(this.relay_id);
      
      document.getElementById("selection2").innerHTML = this.relay_nom;
     // this.client_id=id;

    }, */
       /*  getInfosvendeur(id, nom){
          this.serach = '';
       this.varvendeur = true;
      
        this.seller_id=id;
      this.seller_nom = nom;
      console.log(this.seller_id);
      document.getElementById("selection3").innerHTML = this.seller_nom;
     // this.client_id=id;

    }, */

    /* searchclient(){
      if(this.search){
        this.isLoading=true;
      this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/searchclient/'+this.search,
      })    
     .then(res => {
       this.isLoading = false;
      this.clients = res.data;
      this.count = res.data.length;
     })
      }
          

    }, */
        /* searchrelais(){
          if(this.search){
            this.isLoading=true;
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/searchrelay2/'+this.search,
      })    
     .then(res => {
       this.isLoading=false;
      this.relais = res.data;
      this.count = res.data.length;
     })}

    }, */

    /* searchvendeur(){
      if(this.search){
        this.isLoading=true;
            this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/searchseller/'+this.search,
      })    
     .then(res => {
       this.isLoading=false;
      this.vendeurs = res.data;
      this.count = res.data.length;
     })}

    }, */
    /* test1(){
      if(document.getElementById('b1').clicked == false)
      console.log("nooooooooooo!")

      
       //if($("#b1").click()==null) {console.log("not clicked!")}
    } */



     

     
      
    
    },

   
    
  
}
</script>
<style scoped>
@import url(https://cdn.syncfusion.com/ej2/material.css);
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css"; 


input[type=search]:focus{
  outline: inherit;
  box-shadow: inherit;
  border: none;

}
.pointer {cursor: pointer;}

.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
i{
   color: #6c757d;
   font-size: 30px;


}
.btn-light:hover{
  background : rgb(200, 228, 206);
}


/* a:hover {
  color: rgb(93, 0, 0) !important;
  font-weight: bolder;
    font-weight: inherit;

}
table tr:hover{
color : darkred;
box-shadow:darkred 1.95px 1.95px 2.6px;
font-weight: bolder;
} */
.dropdown-item:hover{
  background: none;
}
/* input[list]
{
  margin-left:auto;
    margin-right:auto;

    max-width: 500px;
    background: #D2E9FF;
    padding: 20px 20px 20px 20px;
    font: 12px Arial, Helvetica, sans-serif;
    color: #666;
} */
::selection{
  color: #fff;
  background: #664AFF;
}
.wrapper{
  max-width: 450px;
  margin: 150px auto;
}
.wrapper .search-input{
  background: #fff;
  width: 100%;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 1px 5px 3px rgba(0,0,0,0.12);
}
/* required fileds */
input:after {
  
}
label:before {
    content: '*';
    font-size: 18px;
    color: red;
  }
/* input[required]:after {
    content: '*';
    
} */
/* .search-input input{
  height: 55px;
  width: 100%;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 0 60px 0 20px;
  font-size: 18px;
  box-shadow: 0px 1px 5px rgba(0,0,0,0.1);
}
.search-input.active input{
  border-radius: 5px 5px 0 0;
} */







</style>
import { Script } from "vm";
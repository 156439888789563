

<template>
              

      <section class="home-section" >
         
         <div class="container-scroller" >
    <!-- partial:partials/_navbar.html -->
    <Header title="Ajout colis" />
    <!-- partial -->
    <div class="container-fluid page-body-wrapper bg-light"  >
      <!-- partial:partials/_sidebar.html -->
   
    
      <!-- partial -->
      <div class="main-panel" style="width : 100%;">
        <!---content wrapper start--->
              
        <div class="content-wrapper">

                  <div class="row" >
            <div class="col-md-12 grid-margin">
              <div class="d-flex justify-content-between flex-wrap row">
                <div class="d-flex align-items-end flex-wrap col-lg-8">
                
               
                <div class="mr-auto p-2"> <div class="mt-3">
                      <p><i class="mdi mdi-map-marker-multiple icon-md" style="color: #6c757d; font-size: 30px;"></i></p>
                    </div>
                    </div>
      


                </div>
                 <div class="col-lg-4 d-flex justify-content-between align-items-end flex-wrap">
                    <div class="input-group w-100 p-0 bg-light" style=" border-bottom: 1px solid black; ">
              <div class="input-group-prepend bg-light" style="height : 40px;">
                <span class="input-group-text bg-light" id="search">
                  <i class="mdi mdi-magnify text-dark"></i>
                </span>
              </div>
              <input type="search" class="form-control bg-light " v-model="searchQuery"  @focus="testallseller(false, false)" placeholder="Search now" aria-label="search" aria-describedby="search" style="height : 40px; border : none; ">
            </div>
                  
                   

                  

                 
<!-- <button @click="t">tester</button> -->
                  
                  
                </div>
                    <!-- <div class="input-group w-100 p-0 bg-light" style=" border-bottom: 1px solid black; ">
                <select class="form-control form-select form-select-lg col-lg-3 bg-secondary text-white" aria-label="Default select example" v-model="ville"   @change="changer">
                      <option selected value="">{{selectedItem}}</option>
                     <option v-for="item in villes" :value="item.city" :key="item.id" >
      {{ item.city }}
    </option>
                      </select>
              <div class="input-group-prepend bg-light" style="height : 40px;">
                <span class="input-group-text bg-light" id="search">
                  <i class="mdi mdi-magnify text-dark"></i>
                </span>
              </div>
              <input type="search" class="form-control bg-light " v-model="search"  v-on:search="searchvendeur()" placeholder="Search now" aria-label="search" aria-describedby="search" style="height : 40px; border : none; ">
            </div> -->
                  
                   

                  

                 
<!-- <button @click="t">tester</button> -->
                  
                  
              </div>
            </div>
          </div>
          
       <!--------------->
     
 
       <!--------------->
          <div class="row">
            <div class=" stretch-card col-lg-12 m-1 p-0">
              <div class="card p-0">
                <div class="card-body p-0">
                    <div class="d-flex justify-content-end py-2">
                                   <loading v-if="isLoading" class="py-2" :active="true" :width="20" :height="20" color="green"></loading>

              <!--          <div class="border-bottom" >
                       <div class="input-group w-100 p-0 bg-light" >
                <select class=" border-0 form-control form-select form-select-md col-lg-4 bg-light text-dark" aria-label="Default select example" v-model="searchVille"   @change="tester">
                      <option selected value="">{{selectedItem}}</option>
                     <option v-for="item in villes" v-bind:value="item.city" :key="item.city" >
      {{ item.city }}
    </option>
                      </select>
              
              <input type="search" class=" border-left form-control bg-light " v-model="searchQuery"   @focus="count = 0"  placeholder="Search now" aria-label="search" aria-describedby="search" style="height : 40px; border : none; ">
           <div class="input-group-prepend bg-light" style="height : 40px;">
                <span class="input-group-text bg-light" id="search">
                  <i class="mdi mdi-magnify text-dark"></i>
                </span>
              </div>
            </div>
                    </div> -->
                      <div class="">
                      <div class="d-inline dropdown mr-2">
  <button class="btn btn-light btn-icon-text dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
    <i class="mdi mdi-view-list btn-icon-prepend"></i>
    Aficher les  relais
  </button>
  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
    <li><a class="dropdown-item" href="#" v-on:click="testallseller(true, false)">
      
      <i class="mdi mdi-check float-left icon-sm mr-2"></i>
      <p class="mb-0 ml-2 text-dark"> Points relais disponibles</p>
      </a></li>
    <li><a class="dropdown-item" href="#" v-on:click="afficherRelaisArchives()"> 
      <i class="mr-2 mdi mdi-close-circle-outline text-danger float-left icon-sm"></i>
      <p class="mb-0 ml-2 text-dark"> Points relais archivés</p>
</a></li>
  </ul>
</div>
<button type="button" class="d-inline btn btn-success mr-2 btn-icon-text" v-on:click="ajouterRelais()"  v-show="role == 'admin' || role == 'back office' || role == 'sous back office'">
  <i class="mdi mdi-plus-circle btn-icon-prepend"></i>
  Ajouter un relais
  </button>
                    </div>

                   


                    
                     </div>
                    
                 
                 
                                                     
                   
                                   <!--  <p v-if="count >=0" class="card-title text-center m-2"> <span v-show="!isLoading" class="text-danger">{{ count }}</span>
                                                              <loading v-if="isLoading" :active="true" :width="10" :height="10" color="black"></loading> resultat(s)</p>
 -->
  <div class="row m-3">
                    <table class="table table-hover table-stripped">
  
  <tbody>
    <tr class="bg-white text-black" style="height : 50px;">
      <th scope="row" >Nom</th>
      <td class="text-center font-weight-bold ">RC</td>
      <td class="text-center font-weight-bold">Adresse</td>
      <td class="text-center font-weight-bold">Ville</td>
      <td class="text-center font-weight-bold"> Responsable </td>
      <td class="text-center font-weight-bold"> Compte </td>
      <td class="text-center font-weight-bold"> Actions </td>
    </tr>
   <tr class="odd text-center" v-if="!countSearch && !error" >
                           <td valign = "top" colspan="7" class="dataTables_empty text-center text-primary bg-light font-weight-bold">
                             No data available in table
                           </td>
                        </tr>
    <tr class="odd text-center" v-if="error" >
                           <td valign = "top" colspan="8" class="dataTables_empty text-center text-danger bg-light font-weight-bold">
                             {{error}}
                           </td>
                        </tr>
    
    
     <tr v-show="countSearch && !error" class="text-center " v-for="(item, index) in resultQuery" :key="item.id" v-bind:index="index" >
      <th class="text-left py-3"  scope="row"> {{item.nom}} </th>
      <td > {{item.rc}} </td>
      <td class=""> {{item.adresse}}  </td>
      <td class=""> {{item.ville.city}}  </td>
      <td class=""> {{item.nom_responsable}}  </td>

      <td v-if="item.is_actived ==0" class="text-center  text-white">
                                  <span class="badge badge-primary">Non inscrit</span>
                                </td>
                                <td class="text-center  text-white" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 0"> 
                                  <span class="badge badge-success">Activé</span>
                               </td>
                                <td class="text-center text-white" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 1"> 
                                  <span class="badge badge-danger">Désactivé</span>
                                 </td>
                                <td class="text-center " v-else-if="item.is_actived == 1 &&  item.user == null "> 
                                <span class="badge badge-dark ">Error</span>
                                </td>

      <td class="text-center">
              <a @click="openModel(item)" title="details" class="" style="cursor: pointer;" ><i class="mdi mdi-mdi mdi-eye icon-sm text-info" ></i></a>
              <a v-show="item.is_archived == 0 && role != 'manager'"  @click="edit(item)"   title="editer" class="px-1" style="cursor: pointer;"><i class="mdi mdi-pencil icon-sm text-success " ></i></a>
              <a v-show="role != 'manager'" v-if="item.is_archived == 0 && selected!=item.id" title="archiver " class="" v-on:click = "showMessage(item.id, index)" style="cursor: pointer;"><i class="mdi mdi-close-circle-outline icon-sm text-danger" ></i></a>
              <a  v-show="role != 'manager'" v-if="item.is_archived == 1 && selected!=item.id"  title="désarchiver " class="px-1" v-on:click = "desarchiver(item.id, index)" style="cursor: pointer;"  ><i  class=" mdi mdi-backup-restore icon-sm text-success"  ></i></a>
              <a href=""> <i v-show="selected==item.id" class=" mdi mdi-check icon-sm " style="color : green;" ></i></a>

            </td>
    </tr>

         <tr v-show="count" class="text-center " v-for="(item, index) in relays" :key="item.id" v-bind:index="index" >
      <th class="text-left py-3"  scope="row"> {{item.nom}} </th>
      <td > {{item.rc}} </td>
      <td class=""> {{item.adresse}}  </td>
      <td class=""> {{item.ville.city}}  </td>
      <td class=""> {{item.nom_responsable}}  </td>

      <td v-if="item.is_actived ==0" class="text-center  text-white">
                                  <span class="badge badge-primary">Non inscrit</span>
                                </td>
                                <td class="text-center  text-white" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 0"> 
                                  <span class="badge badge-success">Activé</span>
                               </td>
                                <td class="text-center text-white" v-else-if="item.is_actived == 1 &&  item.user != null && item.user.is_archived == 1"> 
                                  <span class="badge badge-danger">Désactivé</span>
                                 </td>
                                <td class="text-center " v-else-if="item.is_actived == 1 &&  item.user == null "> 
                                <span class="badge badge-dark ">Error</span>
                                </td>

      <td class="text-center">
              <a @click="openModel(item)" title="details" class="" style="cursor: pointer;" ><i class="mdi mdi-mdi mdi-eye icon-sm text-info" ></i></a>
              <a v-show="item.is_archived == 0"  @click="edit(item)"   title="editer" class="px-1" style="cursor: pointer;"><i class="mdi mdi-pencil icon-sm text-success " ></i></a>
              <a v-if="item.is_archived == 0" title="archiver " class="" v-on:click = "showMessage(item.id, index)" style="cursor: pointer;"><i class="mdi mdi-close-circle-outline icon-sm text-danger" ></i></a>
              <a v-else  title="désarchiver " class="px-1" v-on:click = "desarchiver(item.id, index)" style="cursor: pointer;" v-show="!selected || selected != item.id" ><i  class=" mdi mdi-backup-restore icon-sm text-success"  ></i></a>
              <a href=""> <i v-show="selected==item.id" class=" mdi mdi-check icon-sm " style="color : green;" ></i></a>

            </td>
    </tr>
  </tbody>
</table>
     </div>

<div v-if="myModel">
    <transition name="model">
     <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog  modal-dialog-centered modal-lg ">     
             <div class="modal-content bg-light ">
         <div class="modal-header">
                     <h4 class="modal-title text-center text-primary">Informations</h4>

          <button type="button"  @click="myModel=false"><span aria-hidden="true">&times;</span></button>
         </div>
         <div class="modal-body bg-light">
            <ul class="nav nav-tabs px-4" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active" id="overview-tab" data-toggle="tab" href="#overview" role="tab" aria-controls="overview" aria-selected="true">A propos du Point relais</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" id="sales-tab" data-toggle="tab" href="#sales" role="tab" aria-controls="sales" aria-selected="false">Horraires</a>
                    </li>
                   
                  </ul>
                  <div class="tab-content py-0 px-0">

         <div class="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="overview-tab">
                        <!---------------->
         <div class="table-responsive   mx-3 mt-1  ">
                   <table class="table table-borderless ">

                      <tbody>
                        <tr class="" >
                           <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Réference </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.ref"> {{t.ref}}  </th>
                             <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>
                            
                          </tr >
                          <tr>
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Nom  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.nom}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">raison sociale  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.raison_sociale}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">RC  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.rc}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Adresse  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.adresse}}  </th>
                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Ville  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;"> {{t.ville.city}}  </th>
                            
                          </tr >
                       
                        
                           <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Mobile  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.mobile"> {{t.mobile}}  </th>
                                                          <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>

                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Telephone  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.telephone"> {{t.telephone}}  </th>
                             <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>

                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Fax  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.fax"> {{t.fax}}  </th>
                       <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>

                            
                          </tr >
                          <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Site  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.site_web"> {{t.site_web}}  </th>
                                  <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>

                            
                          </tr >
                            <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Responsable  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.nom_responsable"> {{t.nom_responsable}}  </th>
                                               <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>

                            
                          </tr >
                           <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Montant maximum  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.montant_max"> {{t.montant_max}}DH  </th>
                                               <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>

                            
                          </tr >
                           <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Nombre maximum des colis  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.nbs_colis_max"> {{t.nbr_colis_max}}  </th>
                                               <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>

                            
                          </tr >
                           <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Volume maximum  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.volume_max"> {{t.volume_max}}  </th>
                                               <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>

                            
                          </tr >
                           <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Prix  </th>
                             <th   class="text-muted" style="padding-bottom : 2%;" v-if="t.prix"> {{t.prix}}DH </th>
                                               <th   class="text-muted text-danger" style="padding-bottom : 2%;" v-else> *****  </th>

                            
                          </tr >

                            <tr class="" >
                            <th  style="padding-left : 5%; padding-bottom : 2%; width : 30%;">Compte </th>
                             <th  v-if="t.is_actived ==0" class="text-muted" style="padding-bottom : 2%;">
                              <i class="mdi mdi-account-off text-secondary " style="font-size : 20px;"></i> Non inscrit</th>
                               
                             <th  v-else-if="t.is_actived == 1 &&  t.user != null && t.user.is_archived == 0">
                            <i class="mdi mdi-account-check text-success " style="font-size : 20px;"></i>  Activé
                            </th>
                            <th class="text-muted" style="padding-bottom : 2%;"  v-else-if="t.is_actived == 1 &&  t.user != null && t.user.is_archived == 1">
                               <i class="mdi mdi-account-remove text-danger " style="font-size : 20px;"></i> Désactivé
                            </th>
                            <th class="text-muted" style="padding-bottom : 2%;"  v-else-if="t.is_actived == 1 &&  t.user == null ">
                              Error
                            </th>
                            
                          </tr>
                           <tr  v-if="t.is_actived == 1 &&  t.user != null && t.user.is_archived == 0">
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Date de création  </th>
                             <th class="text-muted" style="padding-bottom : 2%;" >
                              {{format_date(t.user.created_at)}}
                            </th>
                            
                          </tr>
                            <tr  v-if="t.is_actived == 1 &&  t.user != null && t.user.is_archived == 1">
                            <th  style="padding-left : 5%; padding-bottom : 2%;">Date de désactivation  </th>
                             <th class="text-muted" style="padding-bottom : 2%;" >
                              {{format_date(t.user.updated_at)}}
                            </th>
                            
                          </tr>
                            
                            
                      
                      </tbody>
                    
                    </table>
                   
                  </div>
         </div>
         <!------------------>

         <div class="tab-pane container-fluid fade w-90 d-flex justify-content-center" id="sales" role="tabpanel" aria-labelledby="sales-tab">

                        <!---------------->
                    <div class="table-responsive  w-90  " style="overflow-x : hidden !important;">
                        <table class="table table-striped m-2"  >
                          <tbody>
                             <tr class="odd text-center  " v-if="!countHour && !errorHour" >
                           <td valign = "top" colspan="3" class="dataTables_empty text-center text-primary bg-transparent font-weight-bold py-4">
                             Aucun horraire ni associé à ce point relais !
                           </td>
                        </tr>
    <tr class="odd text-center" v-if="errorHour" >
                           <td valign = "top" colspan="3" class="dataTables_empty text-center text-danger bg-transparent font-weight-bold py-4">
                             {{errorHour}}
                           </td>
                        </tr>
    
    <tr v-show="countHour" v-for="(item, index) in hours" :key="item.id" v-bind:index="index">
      <th scope="row" class="py-2"> {{item.jour}} </th>
      <td v-if="!item.off_matin"> {{format_heure(item.h_ouverture_m)}} - {{format_heure(item.h_fermeture_m)}} </td>
      <td v-else><b>OFF</b></td>
      <td v-if="!item.off_soir"> {{format_heure(item.h_ouverture_s)}} - {{format_heure(item.h_fermeture_s)}} </td>
      <td v-else><b>OFF</b></td>
    </tr>
   
  </tbody>
                        </table>
         </div>
         </div>
         </div>
          <br />
          
         </div>
        </div>
       </div>
      </div>
     </div>
    </transition>
   </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>

        <!-- content-wrapper ends -->
        <!-- partial:partials/_footer.html -->
      <Footer />
        <!-- partial -->
      </div>
      <!-- main-panel ends -->
    </div>
    <!-- page-body-wrapper ends -->
  </div>
        
      </section>

</template>

<script>
import Header from '@/components/Header.vue'; 
import Footer from '@/components/Footer.vue'; 
import Swal from 'sweetalert2';
import moment from 'moment';
 import Loading from 'vue3-loading-overlay';








export default {

  components : {
    // Menu,
    Header,
    Footer,
    Loading,
  },
  data()  {
    return {
   chauffeurs : null,
   users : [],
   relais : null,
   count : '',
   search : '',
   ville : '',
   id : "test",
   detail : false,
   isDisabled : false,
   selected : null,
   item : null,
   t : null,
   recherche : {},
   myModel:false,
  actionButton:'Insert',
  dynamicTitle:'Add Data',
  isLoading : false,
  villes : [],
  selectedItem:"Ville ",
  searchQuery : '',
  searchVille : '',
  relays : {},
  activity:{},
    load : false,
    liste : false,
  listearch :false,
  errorHour : '',
  hours:{},
  countHour:0,
   
    }
  },


    mounted(){
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getallcities',
      })    
     .then(res => {
      console.log(res.data);
      this.villes = res.data;
     })
     .catch(err => {
       if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
       else this.error = "Une erreur s'est produite ! "
      })   
    this.testallseller(this.liste, this.listearch)
  },
 computed: {
    role(){
        
             return this.$store.getters.get_profile.type_user;
      },
       user(){
           // console.log(this.$store.getters.get_user)
        return this.$store.getters.get_user;
     },
    
        resultQuery(){
     
      if(this.searchQuery){
      return this.vendeurs.filter((item)=>{
       return this.searchQuery.toLowerCase().split(' ') .every(v =>  item.nom.toLowerCase().includes(this.searchQuery)   ||  item.ville.city.toLowerCase().includes(this.searchQuery) || item.rc.toLowerCase().includes(this.searchQuery) || item.nom_responsable.toLowerCase().includes(this.searchQuery) || item.adresse.toLowerCase().includes(this.searchQuery))
      
      })}
      else if(this.liste ) return this.vendeurs;
      else if(this.listearch) return this.vendeurs;
      else  return []
    },
    countSearch(){
      return this.resultQuery.length;
    },
    },

 methods: {
    testallseller(liste, listarch){
      this.error = '';
     this.isLoading = true;
     
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getrelaisactived',
      })
      .then( res => {
        this.vendeurs = res.data;
        this.liste = liste;
        this.listearch = listarch;
        if(this.liste || this.listearch ) this.searchQuery = "";
        this.isLoading = false;
        //this.count = res.data.length;
      })   
      .catch(err => {
        this.isLoading = false;
        if (!err.status) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })

   },
   tester(){
     if(this.searchVille){
      this.recherche = this.relais.filter((item)=>{
        return   item.ville.city.toLowerCase().includes(this.searchVille) 
      })
      }

   },
    format_date(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY hh:mm')
          }
      },
      format_heure(value){
  
         if (value) {
           return moment(value,'HH:mm:ss').format('HH:mm')
          }
      },
    openModel(item){
  // this.first_name = '';
  // application.last_name = '';
  console.log("clicked!!!")
     this.t = item;
     this.format_heure("");
   
     this.actionButton = "Insert";
     this.dynamicTitle = "Add Data";
     this.myModel = true;
     this.afficherHorraires(item.id)
          console.log(this.countHour+"**************"+this.errorHour)

  },

  afficherHorraires(id){
    console.log("point relais num ",id)
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/gethours/'+id,
      })
      .then( res => {
          this.errorHour = '';
        this.countHour = 0;
        console.log(res.data);
        this.hours = res.data;
        this.countHour = res.data.length;
      })   
      .catch(err => {
        this.errorHour = "Une erreur s'est produite lors du chargement des horraires! "
      })

  },
      ajouterRelais(){
     console.log("clicked!");
     this.$router.push("/addrelay");
         },

          searchRelais(){
            this.isLoading = true;

            this.recherche.ville = this.ville;

              this.$store.dispatch('postItem', {//data to be sended
      nameRoute : '/api/searchrelay',
      t : this.recherche
      })    
     .then(res => {
       this.isLoading = false;
       console.log(res)
       this.relais = res.data;
       console.log(this.relais);
       //this.count = res.data.length;
     })
     .catch(err =>
     {
       this.isLoading = false;
       console.log("error")
       })
    },
    changer(){
      this.searchVille = this.ville;
      if(this.searchVille){
        console.log(this.searchVille)
      this.resultQuery = this.relais.filter((item)=>{
           item.ville.city.toLowerCase().includes(this.searchVille) 
      })
      }else{
        this.resultQuery = [];
        
      }
      return this.resultQuery
    },

    afficherRelais(){
      this.isLoading = true;
         this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getrelaisactived',
      })
      .then( res => {
        this.isLoading = false;
        console.log("*************************",res.data);
        this.searchQuery = '';
        this.ville = '';
        this.relays = res.data;
        //this.count = res.data.length;
      })   
      .catch(err => {
        this.isLoading = false
        this.error = "Une erreur s'est produite ! "
      })
    },

    afficherRelaisArchives(){
      this.error = '';
      this.isLoading = true
     this.$store.dispatch('getItem', {//data to be sended
      nameRoute : '/api/getrelaisarchived',
      })
      .then( res => {
         this.vendeurs = res.data;
        this.searchQuery = '';
       this.liste = false;
       this.listearch = true;
        this.isLoading = false;
       })
      .catch(err => {
        this.isLoading = false
        if (!err.response) this.error = "Problème réseau, Impossible de joindre le serveur" 
        else this.error = "Une erreur s'est produite ! "
      })       
    },
      archiver(id, index){
        this.load = true;
        this.selected = id;
      this.id = id;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/archiverrelais/'+this.id,
      })
     .then(res => {
      // this.$router.go()	// Refreshes page
      /* this.relays.splice(index, 1);
      this.count--; */
      this.isLoading = false;
      this.resultQuery.splice(index, 1);
      this.countSearch--; 
      this.selected = null;
      this.addActivity(id, "Archive relay", '/api/archiverrelais/'+this.id);

      console.log(res.data);
     })
    },
    desarchiver(id, index){
      this.load = true;
      this.selected = id;
      this.id = id;
      this.$store.dispatch('idItem', {//data to be sended
      nameRoute : '/api/desarchiverrelais/'+this.id,
      })
     .then(res => {
       
      this.isLoading = false;
       
      // this.$router.go()	// Refreshes page
       //this.isDisabled=false;
       this.resultQuery.splice(index, 1);
      this.countSearch--; 
      this.addActivity(id, "Desarchive relay", '/api/desarchiverrelais/'+this.id);
      this.selected = null;
      console.log(res.data);
     })
    },

  
    edit( json_data){  

      this.$router.push({ name: 'editR', params: {data:JSON.stringify(json_data)}})

     //this.$router.push(`/detailsDriver/${id}`);
    },
    addActivity(id, action, link){
      this.activity.user_id= this.user.id;
      this.activity.action= action;
      this.activity.model = "Relay"
      this.activity.url=this.$store.getters.get_link+link;
      this.activity.user_agent=navigator.appName+" "+navigator.appVersion ;
      this.activity.item_id = id;
      console.log("activity..........", this.activity)
        this.$store.dispatch('postItem', {//data to be sended
          nameRoute : '/api/addactivity',
          t : this.activity
          })    
        .then(res => {
         // window.location.reload();
          console.log(res.data);
        })
        .catch(error => {
            console.log(error)
           
        }) 

      },

    showMessage(id, index){
      Swal.fire({
      title: 'Attention!',
      text: "Etes vous sur de vouloir archiver ce relais?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'green',
      cancelButtonColor: '#d33',
      cancelButtonText: "Annuler",
      confirmButtonText: 'Ok'
      })
      .then((result) => {
       if (result.isConfirmed) {
         this.archiver(id, index);
     
  }
})
    },

  }
}
</script>

<style scoped>
input[type=search]:focus{
  outline: inherit;
  box-shadow: inherit;
  background: rgb(216, 215, 208) !important;

}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  top: 0;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
  z-index: 2;
}
.sidebar.open ~ .home-section{
  left: 250px;
  width: calc(100% - 250px);
}
.home-section .text{
  display: inline-block;
  color: #11101d;
  font-size: 25px;
  font-weight: 500;
  margin: 0px;
}
/* serachbar */

.search-box{
  width: fit-content;
  height: fit-content;
  position: relative;
}
.input-search{
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 18px;
  outline: none;
  border-radius: 25px;
  transition: all .5s ease-in-out;
  background-color: transparent;
  padding-right: 40px;
  color:black;
}
.input-search::placeholder{
  color: #6C757D;
  font-size: 18px;
  font-weight: 100;
  font-style: italic;
}
.btn-search{
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 25px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color:white ;
  pointer-events: painted;  
}
.btn-search ~ .input-search{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
  border-bottom:1px solid #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
  width: 80%;
  border-radius: 0px;
  background-color: transparent;
   box-shadow: 0  0.2rem #6C757D;
  transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.modal-mask {
     position: fixed;
     z-index: 9998;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: rgba(0, 0, 0, .5);
     display: table;
     transition: opacity .3s ease;
   }

   .modal-wrapper {
     display: table-cell;
     vertical-align: middle;
   }


</style>
import { Script } from "vm";